export enum PermissionConsts {
    // pages
    Pages_Tenants = 'Pages.Tenants',
    Pages_Roles = 'Pages.Roles',
    Pages_Users = 'Pages.Users',
    Pages_Users_Activation = 'Pages.Users.Activation',
    Pages_Company = 'Pages.Company',
    Pages_Training = 'Pages.Training',
    Pages_Invoice = 'Pages.Invoice',
    Pages_Partecipant = 'Pages.Partecipant',
    Pages_Project = 'Pages.Project',
    Pages_Monitoring = 'Pages.Monitoring',

    User_Create = 'Users.Create',
    User_Detail = 'User.Detail',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_Get = 'User.Get',
    Company_Create = 'Company.Create',
    Company_GetAll = 'Company.GetAll',
    Company_Get = 'Company.Get',
    Company_Delete = 'Company.Delete',
    Project_Create = 'Project.Create',
    Project_GetAll = 'Project.GetAll',
    Project_Get = 'Project.Get',
    Project_Update = 'Project.Update',
    Project_Delete = 'Project.Delete',
    ProjectCompany_Create = 'ProjectCompany.Create',
    ProjectCompany_GetAll = 'ProjectCompany.GetAll',
    ProjectCompany_Get = 'ProjectCompany.Get',
    ProjectCompany_Update = 'ProjectCompany.Update',
    ProjectCompany_Delete = 'ProjectCompany.Delete',
    Headquarter_Create = 'Headquarter.Create',
    Headquarter_GetAll = 'Headquarter.GetAll',
    Headquarter_Get = 'Headquarter.Get',
    Headquarter_Update = 'Headquarter.Update',
    Headquarter_Delete = 'Headquarter.Delete',
    //professionals
    Professional_Create = 'Professional.Create',
    Professional_GetAll = 'Professional.GetAll',
    Professional_Get = 'Professional.Get',
    Professional_Update = 'Professional.Update',
    Professional_Delete = 'Professional.Delete',
    //partecipants
    Partecipant_Create = 'Partecipant.Create',
    Partecipant_GetAll = 'Partecipant.GetAll',
    Partecipant_Get = 'Partecipant.Get',
    Partecipant_Update = 'Partecipant.Update',
    Partecipant_Delete = 'Partecipant.Delete',
    //TrainingModule
    TrainingModule_List = 'TrainingModule.List',
    TrainingModule_Create = 'TrainingModule.Create',
    TrainingModule_Update = 'TrainingModule.Update',
    TrainingModule_Delete = 'TrainingModule.Delete',
    TrainingModule_Detail = 'TrainingModule.Detail',
    //interviews
    JobInterview_Create = 'JobInterview.Create',
    JobInterview_GetAll = 'JobInterview.GetAll',
    JobInterview_Get = 'JobInterview.Get',
    JobInterview_Update = 'JobInterview.Update',
    JobInterview_Delete = 'JobInterview.Delete',

    //TrainingLesson
    TrainingLesson_Detail = 'TrainingLesson.Detail',
    TrainingLesson_List = 'TrainingLesson.List',
    TrainingLesson_Create = 'TrainingLesson.Create',
    TrainingLesson_Update = 'TrainingLesson.Update',
    TrainingLesson_Delete = 'TrainingLesson.Delete',

    //files
    File_List = 'File.List',
    File_Detail = 'File.Detail',
    File_Create = 'File.Create',
    File_Update = 'File.Update',
    File_Delete = 'File.Delete',
    //class
    Classroom_Detail = 'Classroom.Detail',
    Classroom_List = 'Classroom.List',
    Classroom_Create = 'Classroom.Create',
    Classroom_Update = 'Classroom.Update',
    Classroom_Delete = 'Classroom.Delete',
    //invoice
    Invoice_List = 'Invoice.List',
    Invoice_Detail = 'Invoice.Detail',
    Invoice_Create = 'Invoice.Create',
    Invoice_Update = 'Invoice.Update',
    Invoice_Delete = 'Invoice.Delete',
    //monitoring //TODO: CAMBIARE APPENA AGGIUNTI PERMESSI
    Monitoring_List = 'Monitoring.List',
    Monitoring_Detail = 'Monitoring.Detail',
    Monitoring_Create = 'Monitoring.Create',
    Monitoring_Update = 'Monitoring.Update',
    Monitoring_Delete = 'Monitoring.Delete',
    // Permits
    Permit_Create = 'Permit.Create',
    Permit_GetAll = 'Permit.GetAll',
    Permit_Get = 'Permit.Get',
    Permit_Update = 'Permit.Update',
    Permit_Delete = 'Permit.Delete',
    // NonAttendance
    NonAttendance_List = 'NonAttendance.List',
    NonAttendance_Create = 'NonAttendance.Create',
    NonAttendance_Detail = 'NonAttendance.Detail',
    NonAttendance_Update = 'NonAttendance.Update',
    NonAttendance_Delete = 'NonAttendance.Delete',
}

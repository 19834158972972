import { inject } from '@angular/core';
import { MultiTenancyService } from '@shared/services/multi-tenancy.service';
import { of, switchMap } from 'rxjs';
import { CanActivateFn, Router } from '@angular/router';
import { AppTenantAvailabilityState } from '@shared/AppEnums';

export const validTenantGuard: CanActivateFn = () => {
    const multiTenancyService = inject(MultiTenancyService);
    const router = inject(Router);
    const savedTenantId = abp.multiTenancy.getTenantIdCookie();
    if (savedTenantId) {
        return of(true);
    } else {
        return multiTenancyService.getTenantId().pipe(
            switchMap((res) => {
                const tenantAvailable = !!res.tenantId && res.state === AppTenantAvailabilityState.Available;
                if (tenantAvailable) {
                    abp.multiTenancy.setTenantIdCookie(res.tenantId);
                    return of(true);
                } else {
                    return router.navigate(['fallback', 'invalid-tenant']);
                }
            })
        );
    }
};

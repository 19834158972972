import { Injectable } from '@angular/core';
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '../session/app-session.service';

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { isArray } from 'lodash-es';
import { PermissionConsts } from '@shared/permission-consts';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
    constructor(private _permissionChecker: PermissionCheckerService, private _router: Router, private _sessionService: AppSessionService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        const permissions = route.data['permission'];
        if (permissions) {
            if (isArray(permissions)) {
                const permissionChecked = permissions.some((permission: string) => this._permissionChecker.isGranted(permission));
                if (permissionChecked) {
                    return true;
                }
            } else if (this._permissionChecker.isGranted(route.data['permission'])) {
                return true;
            }
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted(PermissionConsts.Project_GetAll)) {
            return '/app/project';
        }

        return '/app/project';
    }
}

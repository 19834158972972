import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { CopyToClipboardDirective } from '@shared/directives/copy-to-clipboard.directive';
import { SortingHeaderComponent } from '@shared/components/sorting/sorting-link/sorting-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyCreateFormComponent } from './components/modal/company-create-form/company-create-form.component';
import {
    AddressServiceProxy,
    ClassroomServiceProxy,
    ClassTypeServiceProxy,
    CompanyServiceProxy,
    FileServiceProxy,
    HeadquarterServiceProxy,
    InvoiceServiceProxy,
    JobInterviewServiceProxy,
    MonitoringServiceProxy,
    NonAttendanceServiceProxy,
    PartecipantServiceProxy,
    PartecipantTypeServiceProxy,
    PermitServiceProxy,
    PermitTypeServiceProxy,
    ProfessionalRoleServiceProxy,
    ProfessionalServiceProxy,
    ProjectCompanyServiceProxy,
    ProjectServiceProxy,
    TrainingLessonServiceProxy,
    TrainingModuleServiceProxy,
    YearRangeServiceProxy,
} from './service-proxies/service-proxies';
import { HeadquarterCreateFormComponent } from './components/modal/headquarter-create-form/headquarter-create-form.component';
import { CompanyDeleteComponent } from './components/modal/company-delete/company-delete.component';
import { ProjectCreateFormComponent } from './components/modal/project-create-form/project-create-form.component';
import { AutocompleteLibModule } from '@node_modules/angular-ng-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HeadquarterDeleteFormComponent } from './components/modal/headquarter-delete/headquarter-delete-form.component';
import { ProjectDeleteComponent } from '@shared/components/modal/project-delete/project-delete.component';
import { ProfessionalDeleteFormComponent } from './components/modal/professional-delete-form/professional-delete-form.component';
import { SearchbarFiltersComponent } from '@shared/searchbar-filters/searchbar-filters.component';
import { ProjectCompanyCreateFormComponent } from './components/modal/project-company-create-form/project-company-create-form.component';
import { ProjectCompanyAddProfessionalComponent } from './components/modal/project-company-add-professional/project-company-add-professional.component';
import { ProjectCompanyRemoveProfessionalComponent } from './components/modal/project-company-remove-professional/project-company-remove-professional.component';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { PartecipantDeleteFormComponent } from './components/modal/partecipant-delete-form/partecipant-delete-form.component';
import { FilesListComponent } from '@shared/components/misc/files-list/files-list.component';
import { FileDeleteComponent } from './components/modal/file-delete/file-delete.component';
import { TrainingModuleDeleteComponent } from './components/modal/training-module-delete/training-module-delete.component';
import { FullCalendarModule } from '@node_modules/@fullcalendar/angular';
import { CalendarBaseComponent } from './components/misc/calendar-base/calendar-base.component';
import { TrainingClassCreateComponent } from './components/modal/training-class-create/training-class-create.component';
import { TrainingClassDeleteFormComponent } from './components/modal/training-class-delete-form/training-class-delete-form.component';
import { VolunteerCreateComponent } from './components/modal/training-class-create/volunteer-create-form/volunteer-create.component';
import { VolunteerHeadquarterListComponent } from './components/modal/training-class-create/volunteer-headquarter-list/volunteer-headquarter-list.component';
import { VolunteerDeleteFormComponent } from './components/modal/training-class-create/volunteer-delete-form/volunteer-delete-form.component';
import { InvoiceCreateFormComponent } from './components/modal/invoice-create-form/invoice-create-form.component';
import { MovePartecipantComponent } from './components/modal/move-partecipant/move-partecipant.component';

import { InvoiceDeleteFormComponent } from './components/modal/invoice-delete-form/invoice-delete-form.component';
import { InterviewCreateFormComponent } from '@shared/components/modal/interview-create-form/interview-create-form.component';
import { BsDatepickerModule } from '@node_modules/ngx-bootstrap/datepicker';
import { InterviewDetailsComponent } from './components/modal/interview-details/interview-details.component';
import { MonitoringCreateFormComponent } from './components/modal/monitoring-create-form/monitoring-create-form.component';
import { ExcelImportReportComponent } from './components/modal/excel-import-report/excel-import-report.component';
import { PermitDeleteComponent } from './components/modal/permit-delete/permit-delete.component';
import { PermitCreateFormComponent } from './components/modal/permit-create-form/permit-create-form.component';
import { MonitoringDeleteFormComponent } from './components/modal/monitoring-delete-form/monitoring-delete-form.component';
import { TimepickerModule } from '@node_modules/ngx-bootstrap/timepicker';
import { LessonCreateEditFormComponent } from './components/modal/lesson-create-form/lesson-create-edit-form.component';
import { AssignClassroomComponent } from './components/modal/assign-classroom/assign-classroom.component';
import { EditRecoveryComponent } from './components/modal/edit-recovery/edit-recovery.component';
import { LessonNonAttendancesListComponent } from './components/modal/lesson-create-form/lesson-non-attendances-list/lesson-non-attendances-list.component';
import { TooltipModule } from '@node_modules/ngx-bootstrap/tooltip';
import { CreateProfessionalComponent } from './components/modal/project-company-add-professional/create-professional/create-professional.component';
import { SummaryVolunteerComponent } from './components/modal/summary-volunteer/summary-volunteer.component';
import { SimpleDurationPipe } from './pipes/simple-duration.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        MatAutocompleteModule,
        FullCalendarModule,
        BsDatepickerModule,
        TimepickerModule,
        TooltipModule,
    ],
    declarations: [
        AbpPaginationControlsComponent,
        CopyToClipboardDirective,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        SortingHeaderComponent,
        CompanyCreateFormComponent,
        HeadquarterCreateFormComponent,
        HeadquarterDeleteFormComponent,
        CompanyDeleteComponent,
        ProjectCreateFormComponent,
        ProjectDeleteComponent,
        ProfessionalDeleteFormComponent,
        SearchbarFiltersComponent,
        ProjectCompanyCreateFormComponent,
        ProjectCompanyAddProfessionalComponent,
        ProjectCompanyRemoveProfessionalComponent,
        MomentDatePipe,
        PartecipantDeleteFormComponent,
        FilesListComponent,
        FileDeleteComponent,
        TrainingModuleDeleteComponent,
        CalendarBaseComponent,
        TrainingClassDeleteFormComponent,
        VolunteerCreateComponent,
        VolunteerHeadquarterListComponent,
        VolunteerDeleteFormComponent,
        TrainingClassCreateComponent,
        InvoiceCreateFormComponent,
        MovePartecipantComponent,
        InterviewCreateFormComponent,
        InvoiceDeleteFormComponent,
        InterviewDetailsComponent,
        MonitoringCreateFormComponent,
        ExcelImportReportComponent,
        PermitDeleteComponent,
        PermitCreateFormComponent,
        MonitoringDeleteFormComponent,
        LessonCreateEditFormComponent,
        AssignClassroomComponent,
        EditRecoveryComponent,
        LessonNonAttendancesListComponent,
        CreateProfessionalComponent,
        SummaryVolunteerComponent,
        SimpleDurationPipe,
    ],
    exports: [
        AbpPaginationControlsComponent,
        CopyToClipboardDirective,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        MomentDatePipe,
        BusyDirective,
        EqualValidator,
        SortingHeaderComponent,
        NgSelectModule,
        SearchbarFiltersComponent,
        FilesListComponent,
        CalendarBaseComponent,
        SummaryVolunteerComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AddressServiceProxy,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                CompanyServiceProxy,
                ProjectServiceProxy,
                YearRangeServiceProxy,
                HeadquarterServiceProxy,
                ProfessionalServiceProxy,
                ProjectCompanyServiceProxy,
                ProfessionalRoleServiceProxy,
                ClassroomServiceProxy,
                ClassTypeServiceProxy,
                PartecipantServiceProxy,
                FileServiceProxy,
                TrainingModuleServiceProxy,
                PartecipantTypeServiceProxy,
                InvoiceServiceProxy,
                JobInterviewServiceProxy,
                MonitoringServiceProxy,
                PermitServiceProxy,
                PermitTypeServiceProxy,
                TrainingLessonServiceProxy,
                NonAttendanceServiceProxy,
            ],
        };
    }
}

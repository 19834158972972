import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { validTenantGuard } from '@shared/routing/guards/valid-tenant.guard';

const routes: Routes = [
    { path: '', redirectTo: '/app/project', pathMatch: 'full' },
    {
        path: 'account',
        canActivateChild: [validTenantGuard],
        loadChildren: () => import('account/account.module').then((m) => m.AccountModule), // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'app',
        canActivateChild: [validTenantGuard],
        loadChildren: () => import('app/app.module').then((m) => m.AppModule), // Lazy load account module
        data: { preload: true },
    },
    { path: 'fallback', loadChildren: () => import('./fallback/fallback.module').then((m) => m.FallbackModule) },
    {
        path: '**',
        redirectTo: 'fallback',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {}
